const listProductData = [
    {
        "id": 1,
        "name": "Warman® AHF Horizontal Froth Pump",
        "slug": "warman-ahf-horizontal-froth-pump",
        "short_description": "Warman® horizontal froth pumps are designed to handle heavy froth and viscous concentrated non-Newtonian slurries within several mining applications including flotation, thickening and tailings.",
        "short_description_vn": "Máy bơm bọt ngang Warman® được thiết kế để xử lý các loại bùn đặc phi Newton có nhiều bọt và nhớt trong một số ứng dụng khai thác mỏ bao gồm tuyển nổi, làm đặc và chất thải.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-horizontal-froth-pumps-ahf-lf-and-mf/product-images/16-9-warman-ahf-horizontal-froth-pump.png?width=1300&format=webp&quality=90"
      },
      {
        "id": 2,
        "name": "Warman® MF and LF Horizontal Froth Pumps",
        "slug": "warman-mf-and-lf-horizontal-froth-pumps",
        "short_description": "Warman® horizontal froth pumps are designed to handle heavy froth and viscous concentrated non-Newtonian slurries within several mining applications including flotation, thickening and tailings.",
        "short_description_vn": "Máy bơm bọt ngang Warman® được thiết kế để xử lý các loại bùn đặc phi Newton có nhiều bọt và nhớt trong một số ứng dụng khai thác mỏ bao gồm tuyển nổi, làm đặc và chất thải.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-horizontal-froth-pumps-ahf-lf-and-mf/product-images/Warman_MF_10_Pump_ISO_Front_071122.png?width=1300&format=webp&quality=90"
      },
      {
        "id": 3,
        "name": "Warman® XU Medium-duty Unlined Pumps",
        "slug": "warman-xu-medium-duty-pumps",
        "short_description": "A medium-duty horizontal slurry pump uniquely designed to excel in sand, gravel and coal applications.",
        "short_description_vn": "Máy bơm bùn ngang công suất trung bình được thiết kế độc đáo để vượt trội trong các ứng dụng cát, sỏi và than.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-xu-medium-duty-centrifugal-pumps/product-images/Warman_XU_Pump_ISO_290322.png"
      },
      {
        "id": 4,
        "name": "Warman® WGR® Sand and Aggregate Pump",
        "slug": "warman-wgr-sand-and-aggregate-pump",
        "short_description": "Medium-duty slurry pumps for sand and aggregate applications.",
        "short_description_vn": "Máy bơm bùn công suất trung bình dành cho ứng dụng cát và cốt liệu.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-wgr-sand-and-aggregate-pumps/product-images2/WGR_Front_110320.png"
      },
      {
        "id": 5,
        "name": "Warman® HTP High Pressure Pumps",
        "slug": "warman-htp-high-pressure-pumps",
        "short_description": "Our range of large Warman® HTP high-pressure pumps provide superior performance, wear life and reliability in severe multi-stage, long-distance applications including hydrotransport and tailings.",
        "short_description_vn": "Dòng máy bơm cao áp Warman® HTP cỡ lớn của chúng tôi mang lại hiệu suất vượt trội, tuổi thọ hao mòn và độ tin cậy cao trong các ứng dụng đường dài, nhiều giai đoạn, bao gồm vận chuyển thủy lực và chất thải.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-htp-high-pressure-pumps/product-images/Warman_HTP_600_Pump_ISO_240322.png"
      },
      {
        "id": 6,
        "name": "Warman® AH® Slurry Pump",
        "slug": "warman-ah-slurry-pump",
        "short_description": "Warman® AH® heavy-duty centrifugal slurry pumps are designed for the continuous pumping of highly abrasive, dense slurries in processes from hydrocyclone feed to regrind, flotation and tailings.",
        "short_description_vn": "Máy bơm bùn ly tâm hạng nặng Warman® AH® được thiết kế để bơm liên tục các loại bùn đặc, có độ mài mòn cao trong các quy trình từ cấp liệu hydrocyclone đến nghiền lại, tuyển nổi và chất thải.\r\n",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-heavy-duty-slurry-pumps-ah-and-ahpp/product-images/16-9-warman-ah-slurry-pump-1.png"
      },
      {
        "id": 7,
        "name": "Warman® AHPP Slurry Pump",
        "slug": "warman-ahpp-slurry-pump",
        "short_description": "Warman® AH® heavy-duty centrifugal slurry pumps are designed for the continuous pumping of highly abrasive, dense slurries in processes from hydrocyclone feed to regrind, flotation and tailings.",
        "short_description_vn": "Máy bơm bùn ly tâm hạng nặng Warman® AH® được thiết kế để bơm liên tục các loại bùn đặc, có độ mài mòn cao trong các quy trình từ cấp liệu hydrocyclone đến nghiền lại, tuyển nổi và chất thải.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-heavy-duty-slurry-pumps-ah-and-ahpp/product-images/16-9-warman-ahpp-slurry-pump-1.png"
      },
      {
        "id": 8,
        "name": "Warman® G Gravel Pumps",
        "slug": "warman-g-gravel-pumps",
        "short_description": "Our specialised dredge and gravel range of Warman® horizontal unlined metal pumps.",
        "short_description_vn": "Dòng sản phẩm nạo vét và sỏi chuyên dụng của chúng tôi gồm máy bơm kim loại nằm ngang Warman®.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-heavy-duty-gravel-and-dredge-pumps/product-images/Warman_G10_Pump.png"
      },
      {
        "id": 9,
        "name": "Warman® GH Heavy-duty Gravel Pumps",
        "slug": "warman-gh-heavy-duty-gravel-pumps",
        "short_description": "Our specialised dredge and gravel range of Warman® horizontal unlined metal pumps.",
        "short_description_vn": "Dòng sản phẩm nạo vét và sỏi chuyên dụng của chúng tôi gồm máy bơm kim loại nằm ngang Warman®.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-heavy-duty-gravel-and-dredge-pumps/product-images/Warman_GH8_Pump.png"
      },
      {
        "id": 10,
        "name": "Warman® SHG™ Super-heavy-duty Gravel Pumps",
        "slug": "warman-shg-super-heavy-duty-gravel-pumps",
        "short_description": "Our specialised dredge and gravel range of Warman® horizontal unlined metal pumps.",
        "short_description_vn": "Dòng sản phẩm nạo vét và sỏi chuyên dụng của chúng tôi gồm máy bơm kim loại nằm ngang Warman®.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-mill-circuit-pumps-mcr-mcr-m-mcu/product-images/product-images/16-9-warman-shg400-pump-1.png"
      },
      {
        "id": 11,
        "name": "Warman® GHP and GHPP High-pressure Gravel Pumps",
        "slug": "warman-ghp-and-ghpp-high-pressure-gravel-pumps",
        "short_description": "Our specialised dredge and gravel range of Warman® horizontal unlined metal pumps.",
        "short_description_vn": "Dòng sản phẩm nạo vét và sỏi chuyên dụng của chúng tôi gồm máy bơm kim loại nằm ngang Warman®.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-heavy-duty-gravel-and-dredge-pumps/product-images/Warman_GHPP_Pump_0.png"
      },
      {
        "id": 12,
        "name": "Warman® D Dredging Pumps",
        "slug": "warman-d-dredging-pumps",
        "short_description": "Our specialised dredge and gravel range of Warman® horizontal unlined metal pumps.",
        "short_description_vn": "Dòng sản phẩm nạo vét và sỏi chuyên dụng của chúng tôi gồm máy bơm kim loại nằm ngang Warman®.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-heavy-duty-gravel-and-dredge-pumps/product-images/Warman_D_20_20_Pump_0.png"
      },
      {
        "id": 13,
        "name": "Warman® WBV® Vertical Centrifugal Trash Pumps",
        "slug": "warman-wbv-vertical-centrifugal-trash-pumps",
        "short_description": "Our ultra heavy-duty vertical slurry pumps are the industry benchmark for general sump applications.",
        "short_description_vn": "Our ultra heavy-duty vertical slurry pumps are the industry benchmark for general sump applications.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-wbv-vertical-centrifugal-trash-pumps/product-images2/Warman_WBV_Hybrid_11_6_1.png"
      },
      {
        "id": 14,
        "name": "Warman® SPR Rubber-lined Vertical Sump Pump",
        "slug": "warman-spr-rubber-lined-vertical-sump-pump",
        "short_description": "Available in popular sizes to suit a variety of sump depths, Warman® SP/SPR vertical centrifugal sump pumps are frequently used in mineral, coal, chemical, sugar beet, fly ash and aggregate applications.",
        "short_description_vn": "Có sẵn với các kích cỡ phổ biến để phù hợp với nhiều độ sâu bể phốt khác nhau, máy bơm bể phốt ly tâm trục đứng Warman® SP/SPR thường được sử dụng trong các ứng dụng khoáng sản, than đá, hóa chất, củ cải đường, tro bay và cốt liệu.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-spspr-vertical-centrifugal-trash-pumps/product-images/16-9-warman-spr-100-pump---front-persp-081122-1.png"
      },
      {
        "id": 15,
        "name": "Warman® SP All-metal Vertical Sump Pump",
        "slug": "warman-sp-all-metal-vertical-sump-pump",
        "short_description": "Available in popular sizes to suit a variety of sump depths, Warman® SP/SPR vertical centrifugal sump pumps are frequently used in mineral, coal, chemical, sugar beet, fly ash and aggregate applications.",
        "short_description_vn": "Có sẵn với các kích cỡ phổ biến để phù hợp với nhiều độ sâu bể phốt khác nhau, máy bơm bể phốt ly tâm trục đứng Warman® SP/SPR thường được sử dụng trong các ứng dụng khoáng sản, than đá, hóa chất, củ cải đường, tro bay và cốt liệu.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-spspr-vertical-centrifugal-trash-pumps/product-images/16-9-warman-sp150-pump-front-060720-1.png"
      },
      {
        "id": 16,
        "name": "Warman® 5000 Vertical Cantilever Sump Pump",
        "slug": "warman-5000-vertical-cantilever-sump-pump",
        "short_description": "Heavy-duty, high-head vertical cantilever pumps with unique long shaft design.",
        "short_description_vn": "Máy bơm công xôn trục đứng công suất cao, đầu cao với thiết kế trục dài độc đáo.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-5000-vertical-cantilever-sump-pump/product-images/Warman_5000_Series_Pump_Front_181122.png"
      },
      {
        "id": 17,
        "name": "Multiflo® Mudflo™ Submersible Dredge Pumps",
        "slug": "multiflo-mudflo-submersible-dredge-pumps",
        "short_description": "Multiflo® Mudflo™ submersible dredge pumps are renowned for their efficient and reliable performance across a range of dredging applications.",
        "short_description_vn": "Máy bơm nạo vét chìm Multiflo® Mudflo™ nổi tiếng về hiệu suất hiệu quả và đáng tin cậy trong nhiều ứng dụng nạo vét.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/multiflo-mudflo-submersible-dredge-pump/product-images/Multiflo_Mudflo_150_Pump_Back_Rev_02_211020.png"
      },
      {
        "id": 18,
        "name": "Floway® VTP Vertical Turbine Pumps",
        "slug": "floway-vtp-vertical-turbine-pumps",
        "short_description": "Floway® vertical turbine pumps (VTP) are vertical direct-coupled, single and/or multistage turbine pumps, with a cast or fabricated discharge head, top mounted motor and closed suction or wet pit design. This product is available in a wide range of metallurgies, which are suitable for multiple applications for various markets.",
        "short_description_vn": "Máy bơm tuabin thẳng đứng Floway® (VTP) là máy bơm tuabin trục đứng đơn và/hoặc nhiều tầng khớp nối trực tiếp thẳng đứng, có đầu xả đúc hoặc chế tạo, động cơ gắn trên cùng và thiết kế hố hút hoặc hố ướt kín. Sản phẩm này có sẵn trong nhiều ngành luyện kim, phù hợp cho nhiều ứng dụng cho các thị trường khác nhau.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/floway-vtp-vertical-turbine-pumps/product-images/Floway_VTP_Pump_6.jpg"
      },
      {
        "id": 19,
        "name": "Floway® VTSP Vertical Turbine Solids Pumps",
        "slug": "floway-vtsp-vertical-turbine-solids-pumps",
        "short_description": "Vertical direct-coupled, single or multistage turbine pump, with a cast or fabricated discharge head, top mounted motor and closed suction or wet pit design. The pump has been configured to handle solids contents of up to 10%.",
        "short_description_vn": "Bơm tuabin đơn hoặc nhiều tầng khớp nối trực tiếp thẳng đứng, có đầu xả đúc hoặc chế tạo, động cơ gắn trên cùng và thiết kế hố hút hoặc hố ướt kín. Máy bơm đã được cấu hình để xử lý hàm lượng chất rắn lên tới 10%.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/floway-vtsp-vertical-turbine-solids-pumps/Floway_VTSP_Pump_6.jpg"
      },
      {
        "id": 20,
        "name": "Lewis® Vertical Sulphuric Acid Pumps",
        "slug": "lewis-vertical-sulphuric-acid-pumps",
        "short_description": "Lewis® sulphuric acid pumps are designed to assure the plant operator of outstanding performance and reliability in high-temperature sulphuric acid and oleum applications.",
        "short_description_vn": "Máy bơm axit sulfuric Lewis® được thiết kế để đảm bảo cho người vận hành nhà máy về hiệu suất và độ tin cậy vượt trội trong các ứng dụng axit sulfuric và oleum ở nhiệt độ cao.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/lewis-vertical-sulphuric-acid-pump/product-images/Lewis_18_HTH_111018.png"
      },
      {
        "id": 21,
        "name": "Lewis® Vertical Sulphur Pumps",
        "slug": "lewis-vertical-sulphur-pumps",
        "short_description": "Lewis® vertical centrifugal pumps are a family of engineered-to-order, steam-jacketed sulphur pumps that are designed to meet a variety of applications.",
        "short_description_vn": "Máy bơm ly tâm trục đứng Lewis® là dòng máy bơm lưu huỳnh bọc hơi nước được thiết kế theo đơn đặt hàng, được thiết kế để đáp ứng nhiều ứng dụng khác nhau.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/lewis-vertical-sulphur-pump/product-images/Lewis_Vertical_Sulphur_2_CLS_Pump_Front_071222.png"
      },
      {
        "id": 22,
        "name": "Isogate® Closed-Body (CB) Mechanical Pinch Valves",
        "slug": "isogate-closed-body-cb-mechanical-pinch-valves",
        "short_description": "Reliable pinch valves designed for highly abrasive, flow control applications containing liquid-solids and gas-solids.",
        "short_description_vn": "Van kẹp đáng tin cậy được thiết kế cho các ứng dụng kiểm soát dòng chảy, có độ mài mòn cao chứa chất rắn-lỏng và chất rắn khí.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/isogate-mechanical-and-pneumatic-pinch-valves/product-images/16-9-isogate-closed-body-cb-mechanical-pinch-valves.png"
      },
      {
        "id": 23,
        "name": "Isogate® Open-Body (OB) Mechanical Pinch Valves",
        "slug": "isogate-open-body-ob-mechanical-pinch-valves",
        "short_description": "Reliable pinch valves designed for highly abrasive, flow control applications containing liquid-solids and gas-solids.",
        "short_description_vn": "Van kẹp đáng tin cậy được thiết kế cho các ứng dụng kiểm soát dòng chảy, có độ mài mòn cao chứa chất rắn-lỏng và chất rắn khí.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/isogate-mechanical-and-pneumatic-pinch-valves/product-images/16-9-isogate-open-body-ob-mechanical-pinch-valves.png"
      },
      {
        "id": 24,
        "name": "Isogate® PPV1PS Pneumatic Pinch Valves",
        "slug": "isogate-ppv-1-ps-pneumatic-pinch-valves",
        "short_description": "Reliable pinch valves designed for highly abrasive, flow control applications containing liquid-solids and gas-solids.",
        "short_description_vn": "Van kẹp đáng tin cậy được thiết kế cho các ứng dụng kiểm soát dòng chảy, có độ mài mòn cao chứa chất rắn-lỏng và chất rắn khí.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/isogate-mechanical-and-pneumatic-pinch-valves/product-images/Isogate_1_PS_150_NB_190917.png"
      },
      {
        "id": 25,
        "name": "Isogate® PPV2PL Pneumatic Pinch Valves",
        "slug": "isogate-ppv-2-pl-pneumatic-pinch-valves",
        "short_description": "Reliable pinch valves designed for highly abrasive, flow control applications containing liquid-solids and gas-solids.",
        "short_description_vn": "Van kẹp đáng tin cậy được thiết kế cho các ứng dụng kiểm soát dòng chảy, có độ mài mòn cao chứa chất rắn-lỏng và chất rắn khí.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/isogate-mechanical-and-pneumatic-pinch-valves/product-images/Isogate_2_PL_150_NB_190917.png"
      },
      {
        "id": 26,
        "name": "Isogate® CV Flow Check Valve",
        "slug": "isogate-cv-flow-check-valve",
        "short_description": "Designed to prevent reverse flow in harsh, abrasive applications.",
        "short_description_vn": "Được thiết kế để ngăn dòng chảy ngược trong các ứng dụng khắc nghiệt, mài mòn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/isogate-check-valves/product-images/Isogate_CV_190917.png"
      },
      {
        "id": 27,
        "name": "Isogate® Autoball™ AB3 Series Check Valve",
        "slug": "isogate-autobal-ab-3-series-check-valve",
        "short_description": "Designed to prevent reverse flow in harsh, abrasive applications.",
        "short_description_vn": "Được thiết kế để ngăn dòng chảy ngược trong các ứng dụng khắc nghiệt, mài mòn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/isogate-check-valves/product-images/Isogate_Auto_Ball_Valve.png"
      },
      {
        "id": 28,
        "name": "Isogate® WH/WHP Series High-Pressure Valve",
        "slug": "isogate-wh-whp-series-high-pressure-valve",
        "short_description": "Heavy-duty knife gate valves for abrasive and corrosive applications.",
        "short_description_vn": "Van cổng dao công suất cao dành cho các ứng dụng mài mòn và ăn mòn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/isogate-wide-body-knife-gate-valves/product-images/16-9-wh-orange.png"
      },
      {
        "id": 29,
        "name": "Isogate® WB Wide-Body Valve Series",
        "slug": "isogate-wb-wide-body-valve-series",
        "short_description": "Heavy-duty knife gate valves for abrasive and corrosive applications.",
        "short_description_vn": "Van cổng dao công suất cao dành cho các ứng dụng mài mòn và ăn mòn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/isogate-wide-body-knife-gate-valves/product-images/16-9-wb-orange.png"
      },
      {
        "id": 30,
        "name": "Accumin® Automatic Lubricators",
        "slug": "accumin-automatic-lubricators",
        "short_description": "Heavy-duty knife gate valves for abrasive and corrosive applications.",
        "short_description_vn": "Van cổng dao công suất cao dành cho các ứng dụng mài mòn và ăn mòn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/siteassets/image-blocks/accumin-2"
      },
      {
        "id": 31,
        "name": "Isogate® WR Series Wafer-Style Valve",
        "slug": "isogate-wr-series-wafer-style-valve",
        "short_description": "Lightweight sturdy knife gate valves for abrasive process flow conditions.\r\n",
        "short_description_vn": "Van cổng dao nhẹ chắc chắn cho điều kiện dòng chảy của quá trình mài mòn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/hero-images/isogate-wr-hero-angled"
      },
      {
        "id": 32,
        "name": "Isogate® WS Series Wafer-Style Valve",
        "slug": "isogate-ws-series-wafer-style-valve",
        "short_description": "Lightweight sturdy knife gate valves for abrasive process flow conditions.\r\n",
        "short_description_vn": "Van cổng dao nhẹ chắc chắn cho điều kiện dòng chảy của quá trình mài mòn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/hero-images/isogate-ws-front-small-wide"
      },
      {
        "id": 33,
        "name": "Delta Industrial™ Class 150 Knife Gate Valves",
        "slug": "delta-industrial-class-150-knife-gate-valves",
        "short_description": "Zero Leaks. Less Interruptions. Less downtime.",
        "short_description_vn": "Không rò rỉ. Ít gián đoạn hơn. Ít thời gian chết hơn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/delta-industrial-knife-gate-valves/product-images/16-9delta-industrial-class-150-knife-gate-valves.png"
      },
      {
        "id": 34,
        "name": "Delta Industrial™ CBB Knife Gate Valves",
        "slug": "delta-industrial-cbb-knife-gate-valves",
        "short_description": "Zero Leaks. Less Interruptions. Less downtime.",
        "short_description_vn": "Không rò rỉ. Ít gián đoạn hơn. Ít thời gian chết hơn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/delta-industrial-knife-gate-valves/product-images/16-9-delta-industrial-class-150-knife-gate-valves.png"
      },
      {
        "id": 35,
        "name": "Delta Industrial™ Class 300 Knife Gate Valves",
        "slug": "delta-industria-class-300-knife-gate-valves",
        "short_description": "Zero Leaks. Less Interruptions. Less downtime.",
        "short_description_vn": "Không rò rỉ. Ít gián đoạn hơn. Ít thời gian chết hơn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/delta-industrial-knife-gate-valves/product-images/16_9_Delta_Industrial_300_HD_Valve_Front_Pers_Rev_03_240821.jpg"
      },
      {
        "id": 36,
        "name": "Delta Industrial™ Class 600 Knife Gate Valves",
        "slug": "delta-industrial-class-600-knife-gate-valves",
        "short_description": "Zero Leaks. Less Interruptions. Less downtime.",
        "short_description_vn": "Không rò rỉ. Ít gián đoạn hơn. Ít thời gian chết hơn.",
        "brand": "Weir minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/delta-industrial-knife-gate-valves/product-images/16-9-delta-industrial-class-600-knife-gate-valves.png"
      },
      {
        "id": 37,
        "name": "Cavex® 2 High-Efficiency Hydrocyclone",
        "slug": "cavex-2-high-efficiency-hydrocyclone",
        "short_description": "The Cavex® 2 high-efficiency cyclone marks a new era in hydrocyclone separation technology with the ability to reduce turbulence and improve classification of feed slurry by up to 30%.",
        "short_description_vn": "Lốc xoáy hiệu suất cao Cavex® 2 đánh dấu một kỷ nguyên mới trong công nghệ tách hydrocyclone với khả năng giảm nhiễu loạn và cải thiện khả năng phân loại bùn thức ăn lên tới 30%.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/cavex-2-high-efficiency-hydrocyclone/product-images-2/cavex2-650cvd---side-per-rev02-071020"
      },
      {
        "id": 38,
        "name": "Cavex® CVX Classifying Hydrocyclone",
        "slug": "cavex-cvx-hydrocyclone",
        "short_description": "Our original Cavex® CVX hydrocyclone is recognised for its innovative laminar spiral inlet geometry design. Compared to other separation equipment, this hydrocyclone has a smaller footprint, high capacity and low capital cost.",
        "short_description_vn": "Hydrocyclone Cavex® CVX nguyên bản của chúng tôi được công nhận nhờ thiết kế hình học cửa vào xoắn ốc nhiều lớp cải tiến. So với các thiết bị phân tách khác, hydrocyclone này có diện tích nhỏ hơn, công suất cao và chi phí vốn thấp.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/cavex-cvx-classifying-hydrocyclone/product-images/1_Cavex_700cvx_With_Logo_2_Locations.jpg"
      },
      {
        "id": 39,
        "name": "Cavex® CVXT Ceramic Hydrocyclone",
        "slug": "cavex-cvxt-ceramic-hydrocyclone",
        "short_description": "Our heavy-duty Cavex® CVXT ceramic-tiled cyclone is designed to provide efficient classification and longer wear life in dense-medium applications, including coal and diamonds.",
        "short_description_vn": "Lốc xoáy lát gốm Cavex® CVXT hạng nặng của chúng tôi được thiết kế để cung cấp khả năng phân loại hiệu quả và tuổi thọ mài mòn lâu hơn trong các ứng dụng có mật độ trung bình dày đặc, bao gồm than đá và kim cương.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/cavex-cvxt-ceramic-hydrocyclone/product-images/product-images/cvxt-on-cradle_mod"
      },
      {
        "id": 40,
        "name": "Cavex® CVXA Dense-Medium Hydrocyclone",
        "slug": "cavex-cvxa-dense-medium-hydrocyclone",
        "short_description": "The Cavex® CVXA dense-medium cyclone is designed to provide exceptional performance in heavy-media applications.",
        "short_description_vn": "Lốc xoáy trung bình dày đặc Cavex® CVXA được thiết kế để mang lại hiệu suất vượt trội trong các ứng dụng phương tiện nặng.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/cavex-cvxa-dense-medium-hydrocyclone/product-images/Cavex_650_CVXA_Hydrocyclone_Front_101122.png"
      },
      {
        "id": 41,
        "name": "Cavex® CVXU Polyurethane Hydrocyclone",
        "slug": "cavex-cvxu-polyurethane-hydrocyclone",
        "short_description": "Commonly used in desliming, the Cavex® CVXU polyurethane hydrocyclone provides strength and corrosion resistance in light-duty and low-temperature applications.",
        "short_description_vn": "Thường được sử dụng trong quá trình khử chất béo, hydrocyclone polyurethane Cavex® CVXU cung cấp độ bền và khả năng chống ăn mòn trong các ứng dụng nhẹ và nhiệt độ thấp.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/cavex-cvxu-polyurethane-hydrocyclone/product-images2/product-images/Cavex_250_CVXU_Hydrocyclone_Front_111122.png"
      },
      {
        "id": 42,
        "name": "Cavex® DE Hydrocyclone",
        "slug": "cavex-de-hydrocyclone",
        "short_description": "The Cavex® DE cyclone is a double-efficiency classification unit, with two stages of separation incorporated into the one piece of equipment. It works without the need for intermediary pumping, piping or sumps.",
        "short_description_vn": "Cyclone Cavex® DE là thiết bị phân loại hiệu suất kép, với hai giai đoạn tách được tích hợp vào một thiết bị. Nó hoạt động mà không cần bơm, đường ống hoặc hố thu trung gian.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/cavex-de-desliming-hydrocyclone/product-images/Cavex_CVX_DE_500_400_Cyclone_Front_230822.png"
      },
      {
        "id": 43,
        "name": "Cavex® Hydrocyclone Air Core Boosters",
        "slug": "cavex-hydrocyclone-air-core-boosters",
        "short_description": "Our range of Cavex® Air Core Boosters (ACB) are a popular cyclone product that enhance classification and overall hydrocyclone performance.",
        "short_description_vn": "Dòng sản phẩm Cavex® Air Core Boosters (ACB) của chúng tôi là sản phẩm lốc xoáy phổ biến giúp nâng cao khả năng phân loại và hiệu suất tổng thể của hydrocyclone.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/cavex-hydrocyclone-air-core-boosters/product-images/Air_Core_Booster_Left_040320.png"
      },
      {
        "id": 44,
        "name": "NSX, NSN",
        "slug": "nsx-nsn",
        "short_description": "Single stage horizontal SS centrifugal pumps",
        "short_description_vn": "Bơm ly tâm SS ngang một tầng",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-960-720/nsx-nsn-6.jpg"
      },
      {
        "id": 45,
        "name": "MX, MN",
        "slug": "mx-mn",
        "short_description": "Single stage horizontal SS centrifugal pumps",
        "short_description_vn": "Bơm ly tâm SS ngang một tầng",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-960-720/mx-mn-6.jpg"
      },
      {
        "id": 46,
        "name": "MXO/MXC",
        "slug": "mxo-mxc",
        "short_description": "Single stage horizontal SS centrifugal pumps",
        "short_description_vn": "Bơm ly tâm SS ngang một tầng",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-960-720/mxc.jpg"
      },
      {
        "id": 47,
        "name": "HF, HX and HN",
        "slug": "hf-hx-and-hn",
        "short_description": "Horizontal multistage centrifugal pumps",
        "short_description_vn": "Bơm ly tâm ngang nhiều tầng",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/chlf20-40lswsc-t-7.jpg"
      },
      {
        "id": 48,
        "name": "VDROO Series",
        "slug": "vdroo-series",
        "short_description": "Vertical multistage pumps & Booster sets",
        "short_description_vn": "Máy bơm trục đứng đa tầng cánh & bộ tăng áp",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/vdroo-detalle-imagen-02-hydroo-2-4.jpg"
      },
      {
        "id": 49,
        "name": "Coolant immersion type vertical pump VKF VKX VKN",
        "slug": "coolant-immersion-type-vertical-pump-vkf-vkx-vkn",
        "short_description": "Vertical multistage pumps & Booster sets",
        "short_description_vn": "Máy bơm trục đứng đa tầng cánh & bộ tăng áp",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/vkf-vkx-vkn-2.jpg"
      },
      {
        "id": 50,
        "name": "High pressure sets VTP",
        "slug": "high-pressure-sets-vtp",
        "short_description": "Vertical multistage pumps & Booster sets",
        "short_description_vn": "Máy bơm trục đứng đa tầng cánh & bộ tăng áp",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/hp-2-7.jpg"
      },
      {
        "id": 51,
        "name": "XBOOST Booster sets",
        "slug": "xboost-booster-sets",
        "short_description": "Vertical multistage pumps & Booster sets",
        "short_description_vn": "Máy bơm trục đứng đa tầng cánh & bộ tăng áp",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/xboost2-1252203.jpg"
      },
      {
        "id": 52,
        "name": "SP",
        "slug": "sp",
        "short_description": "Submersible borehole pumps",
        "short_description_vn": "Máy bơm giếng khoan chìm",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/sjp-pump.jpg"
      },
      {
        "id": 53,
        "name": "SX, SN",
        "slug": "sx-sn",
        "short_description": "Submersible borehole pumps",
        "short_description_vn": "Máy bơm giếng khoan chìm",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/sx-sn-6.jpg"
      },
      {
        "id": 54,
        "name": "HYO - HYA Motors",
        "slug": "hyo-hya-motors",
        "short_description": "Submersible borehole pumps",
        "short_description_vn": "Máy bơm giếng khoan chìm",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/hyo4-hya4-hyo6-hya6.jpg"
      },
      {
        "id": 55,
        "name": "SXB",
        "slug": "sxb",
        "short_description": "Submersible borehole pumps",
        "short_description_vn": "Máy bơm giếng khoan chìm",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/sxb-2-2.jpg"
      },
      {
        "id": 56,
        "name": "HSC",
        "slug": "hsc",
        "short_description": "Single stage double suction split casing centrifugal pumps",
        "short_description_vn": "Máy bơm ly tâm vỏ hút đôi một tầng",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/110-1-2.jpg"
      },
      {
        "id": 57,
        "name": "WDROO",
        "slug": "wdroo",
        "short_description": "Submersible sewage pumps",
        "short_description_vn": "Máy bơm nước thải chìm",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/wdroo-6.jpg"
      },
      {
        "id": 58,
        "name": "WUP and WRAINOX",
        "slug": "wup-and-wrainox",
        "short_description": "Submersible sewage pumps",
        "short_description_vn": "Máy bơm nước thải chìm",
        "brand": "Hydroo Pump",
        "image_url": "https://images.gestionaweb.cat/821/imgf-640-480/wup-5.jpg"
      },
      {
        "id": 59,
        "name": "Warman® MCR® Rubber-lined Pump",
        "slug": "warman-mcr-rubber-lined-pump",
        "short_description": "Our range of Warman® mill circuit pumps are designed to provide superior performance, wear life and reliability in aggressive slurry applications.",
        "short_description_vn": "Dòng máy bơm tuần hoàn máy nghiền Warman® của chúng tôi được thiết kế để mang lại hiệu suất vượt trội, tuổi thọ mài mòn và độ tin cậy trong các ứng dụng bùn mạnh.\r\n",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-mill-circuit-pumps-mcr-mcr-m-mcu/product-images/product-images/16-9warman-mcr-rubber-lined-pump.png"
      },
      {
        "id": 60,
        "name": "Warman® MCR®-M All-metal Pump",
        "slug": "warman-mcr-m-all-metal-pump",
        "short_description": "Our range of Warman® mill circuit pumps are designed to provide superior performance, wear life and reliability in aggressive slurry applications.",
        "short_description_vn": "Dòng máy bơm tuần hoàn máy nghiền Warman® của chúng tôi được thiết kế để mang lại hiệu suất vượt trội, tuổi thọ mài mòn và độ tin cậy trong các ứng dụng bùn mạnh.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-mill-circuit-pumps-mcr-mcr-m-mcu/product-images/product-images/Warman_MCR_M_550_Pump_ISO_051222.png"
      },
      {
        "id": 61,
        "name": "Warman® MCU® Unlined Pump",
        "slug": "warman-mcu-unlined-pump",
        "short_description": "Our range of Warman® mill circuit pumps are designed to provide superior performance, wear life and reliability in aggressive slurry applications.",
        "short_description_vn": "Dòng máy bơm tuần hoàn máy nghiền Warman® của chúng tôi được thiết kế để mang lại hiệu suất vượt trội, tuổi thọ mài mòn và độ tin cậy trong các ứng dụng bùn mạnh.",
        "brand": "Weir Minerals",
        "image_url": "https://www.global.weir/globalassets/product-images/warman-mill-circuit-pumps-mcr-mcr-m-mcu/product-images/product-images/16-9warman-mcu-unlined-pump.png"
      }
];

const Menu = [
  {
      "id": "1",
      "name": "Horizontal pump",
      "path": "weir-minerals-horizontal-pump"
  },
  {
      "id": "2",
      "name": "Vertical Pump",
      "path": "weir-minerals-vertical-pump"
  },
  {
      "id": "3",
      "name": "Slurry Valves",
      "path": "weir-minerals-slurry-valves"
  },
  {
      "id": "4",
      "name": "Knife Gate Valves",
      "path": "weir-minerals-knife-gate-valves"
  },
  {
      "id": "5",
      "name": "Cavex® Cyclone Canisters and Clusters",
      "path": "weir-minerals-cavex-cyclone-canisters-and-clusters"
  },
  {
      "id": "6",
      "name": "Horizontal pump",
      "path": "hydroo-horizontal-pump"
  },
  {
      "id": "7",
      "name": "Vertical Pump",
      "path": "hydroo-vertical-pump"
  },
  {
      "id": "8",
      "name": "Submersible borehole pumps",
      "path": "hydroo-submersible-borehole-pumps"
  },
  {
      "id": "9",
      "name": "Single stage double suction split casing centrifug",
      "path": "hydroo-single-stage-double-suction-split-casing-centrifugal-pumps"
  },
  {
      "id": "10",
      "name": "Submersible sewage pumps",
      "path": "hydroo-submersible-sewage-pumps"
  }
]

const menuPro = [
  {"name": "Horizontal pump", "value": "horizontal-pump"},
  {"name": "Vertical Pump", "value": "vertical-pump"},
  {"name": "Slurry Valves", "value": "slurry-valves"},
  {"name": "Knife Gate Valves", "value": "knife-gate-valves"},
  {"name": "Cavex® Cyclone Canisters and Clusters", "value": "cavex-cyclone-canisters-and-clusters"},
  {"name": "Submersible borehole pumps", "value": "submersible-borehole-pumps"},
  {"name": "Single stage double suction split casing centrifug", "value": "single-stage-double-suction-split-casing-centrifug"},
  {"name": "Submersible sewage pumps", "value": "submersible-sewage-pumps"}
];

export  { listProductData, Menu ,menuPro }